import { Component, OnInit, ViewEncapsulation, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../_services/user.service';

import { LocalStorageService, LocalStorage } from 'ngx-webstorage';

import { appConfig } from '../../app.config';

@Component({
  selector: 'app-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class AppMenuComponent implements OnInit {
  private appConfig = appConfig;

  private devPath = '';
  private devMode: boolean = appConfig.devMode;

  @LocalStorage() public currentUser;
  @LocalStorage() public lang;

  sideMenuActive;

  constructor(
    private _userService: UserService,
    private _storage: LocalStorageService,
    public _activeRouter: ActivatedRoute,
    public _router: Router
  ) {
    if (this.devMode) this.devPath = appConfig.devUrl;

    this.sideMenuActive = _activeRouter.snapshot.data['sideMenu'];
  }

  ngOnInit() {
  }

}
