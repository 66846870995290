import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { appConfig } from '../app.config';

import { LocalStorageService, LocalStorage } from 'ngx-webstorage';

import { Observable } from 'rxjs';

@Injectable()
export class CollectService {

  private appConfig = appConfig;
  private devPath = '';
  private devMode: boolean = this.appConfig.devMode;

  private toggleCollectUrl = '/api/v1/collect/toggle_collect';

  constructor(
    private _http: HttpClient,
    private _storage: LocalStorageService,
    public _router: Router
  ) {
    if (this.devMode) { this.devPath = this.appConfig.localServerUrl; }
  }

	/*
	 *
	 * 		Collect
	 *
	 */

  // 針對景點點選收藏
  toggleCollect(data): Observable<any> {
    let uri = this.devPath + this.toggleCollectUrl;

    return this._http.post(uri, data);
  }

}
