import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { appConfig } from '../app.config';

import { LocalStorageService, LocalStorage } from 'ngx-webstorage';

import { Observable } from 'rxjs';

@Injectable()
export class StarService {

  private appConfig = appConfig;
  private devPath = '';
  private devMode: boolean = this.appConfig.devMode;

  private writeStarUrl = '/api/v1/star/write_star';
  private uploadImageUrl = '/api/v1/star/upload_image';
  private updateStarUrl = '/api/v1/star/update_star';
  private getListUrl = '/api/v1/star/get_list';
  private getOnceUrl = '/api/v1/star/get_once';

  private toggleFavoriteUrl = '/api/v1/star/toggle_favorite';

  constructor(
    private _http: HttpClient,
    private _storage: LocalStorageService,
    public _router: Router
  ) {
    if (this.devMode) { this.devPath = this.appConfig.localServerUrl; }
  }

	/*
	 *
	 * 		Star
	 *
	 */

  // 針對景點去評論
  writeStar(data): Observable<any> {
    let uri = this.devPath + this.writeStarUrl;

    return this._http.post(uri, data);
  }

  // 上傳景點澳片
  uploadImage(data): Observable<any> {
    let uri = this.devPath + this.uploadImageUrl;

    return this._http.post(uri, data);
  }

  // 修改評論
  updateStar(data): Observable<any> {
    let uri = this.devPath + this.updateStarUrl;

    return this._http.post(uri, data);
  }

  // 取得評論清單
  getList(data): Observable<any> {
    let uri = this.devPath + this.getListUrl;

    return this._http.get(uri, { params: data });
  }

  // 取得評論內容
  getOnce(data): Observable<any> {
    let uri = this.devPath + this.getOnceUrl;

    return this._http.get(uri, { params: data });
  }

	/*
	 *
	 * 		Favorite
	 *
	 */

  // 針對評論點選喜歡
  toggleFavorite(data): Observable<any> {
    let uri = this.devPath + this.toggleFavoriteUrl;

    return this._http.post(uri, data);
  }

}
