import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './_guards/auth-guard.service';

/**
 * 頁面開始
 */
import { FrontIndexComponent } from './_pages/front-index/front-index.component';
import { FrontSigninComponent } from './_pages/front-signin/front-signin.component';
import { FrontSignupComponent } from './_pages/front-signup/front-signup.component';
import { FrontPrivacyComponent } from './_pages/front-privacy/front-privacy.component';

import { AppDiscoverComponent } from './_pages/app-discover/app-discover.component';

export const router: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: 'index', component: FrontIndexComponent, data: { sideMenu: 'index' } },
  { path: 'signin', component: FrontSigninComponent, data: { sideMenu: 'signin' } },
  { path: 'signup', component: FrontSignupComponent, data: { sideMenu: 'signup' } },
  { path: 'privacy', component: FrontPrivacyComponent, data: { sideMenu: 'privacy' } },

  { path: 'discover', component: AppDiscoverComponent, canActivate: [AuthGuardService], data: { sideMenu: 'discover' } },
];

export const routes: ModuleWithProviders = RouterModule.forRoot(router);