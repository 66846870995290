import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { appConfig } from '../app.config';

import { LocalStorageService, LocalStorage } from 'ngx-webstorage';

import { Observable } from 'rxjs';

@Injectable()
export class TypeService {

  private appConfig = appConfig;
  private devPath = '';
  private devMode: boolean = this.appConfig.devMode;

  private getListUrl = '/api/v1/type/get_list';

  constructor(
    private _http: HttpClient,
    private _storage: LocalStorageService,
    public _router: Router
  ) {
    if (this.devMode) { this.devPath = this.appConfig.localServerUrl; }
  }

	/*
	 *
	 * 		Type
	 *
	 */

  // 抓取所有分類
  getList(data): Observable<any> {
    let uri = this.devPath + this.getListUrl;

    return this._http.get(uri, data);
  }

}
