import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { appConfig } from '../app.config';

import { LocalStorageService, LocalStorage } from 'ngx-webstorage';

import { Observable } from 'rxjs';

@Injectable()
export class UserService {

  private appConfig = appConfig;
  private devPath = '';
  private devMode: boolean = this.appConfig.devMode;

  private authExistEmailUrl = '/api/v1/auth/exist_email';
  private userUploadAvatarUrl = '/api/v1/user/upload_avatar';
  private authEmailSignupUrl = '/api/v1/auth/email_signup';
  private authEmailSigninUrl = '/api/v1/auth/email_signin';

  constructor(
    private _http: HttpClient,
    private _storage: LocalStorageService,
    public _router: Router
  ) {
    if (this.devMode) { this.devPath = this.appConfig.localServerUrl; }
  }

	/*
	 *
	 * 		User
	 *
	 */
  
  // 檢查用戶信箱是否已經被註冊
  authExistEmail(data): Observable<any> {
    let uri = this.devPath + this.authExistEmailUrl;

    return this._http.get(uri, { params: data });
  }


  // 讓用戶可以上傳圖片
  userUploadAvatar(data): Observable<any> {
    let uri = this.devPath + this.userUploadAvatarUrl;

    return this._http.post(uri, data);
  }

  // 用戶進行註冊
  authEmailSignup(data): Observable<any> {
    let uri = this.devPath + this.authEmailSignupUrl;

    return this._http.post(uri, data);
  }

  // 用戶進行登入
  authEmailSignin(data): Observable<any> {
    let uri = this.devPath + this.authEmailSigninUrl;

    return this._http.post(uri, data);
  }

  // 用戶進行登出
  logout() {
    this._storage.clear('currentUser');
    this._router.navigate(['/signin']);
  }

}
