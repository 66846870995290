import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'datetimeTranslate'
})
export class DatetimeTranslatePipe implements PipeTransform {

  constructor(private _translate: TranslateService) {}

  transform(value: any, args?: any): any {
    if (!value) return value;

    const now = Date.now();
    const btDate = (now - value) / 1000;

    if (btDate <= 60) {
      return  this.getTranslateData('DISCOVER.NOW_AGO')
    } else if (btDate / 60 <= 60) {
      return Math.round(btDate / 60) + this.getTranslateData('DISCOVER.MIN_AGO');
    } else if (btDate / (60 * 60) <= 60) {
      return Math.round(btDate / (60 * 60)) + this.getTranslateData('DISCOVER.HOUR_AGO');
    } else if (btDate / (60 * 60 * 24) <= 24) {
      return Math.round(btDate / (60 * 60 * 24)) + this.getTranslateData('DISCOVER.DAY_AGO');
    } else if (btDate / (60 * 60 * 24) <= 365) {
      return Math.round(btDate / (60 * 60 * 24 * 30)) + this.getTranslateData('DISCOVER.MONTH_AGO');
    } else if (btDate / (60 * 60 * 24) > 365) {
      return Math.round(btDate / (60 * 60 * 24 * 365)) + this.getTranslateData('DISCOVER.YEAR_AGO');
    } else {
      return null;
    }
  }

  // 取得語言內容
  private getTranslateData(name) {
    return this._translate.get(name)['value'];
  }

}
