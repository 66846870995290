export const appConfig = {
  version: "0.0.1",
  title: 'MapsWalker', // 標題
  description: "Mapswalker uses the way that can let users share the trip by tagging. Users can rapidly understand what they have been to, then sharing to friends. If you want to know more about Mapswalker, follow our steps and let us begin the whole new trip.",
  author: "PieWalker Team",
    
  devMode: true,
  devUrl: 'https://www.mapswalker.com',
  pathUrl: 'https://www.mapswalker.com',
  localUrl: 'http://localhost:4200',
  localServerUrl: 'http://localhost:3000',
    
  image: {
      default: 'assets/img/default.png' // 用戶預設
  },
  lang: ["zh-tw", "en-us"],
  langDefault: "en-us",
  facebook: {
      appID: "",
      version: ""
  },
  google: {
    mapsApiKey: "AIzaSyDqByjbSt1JS8ig3R9HjieT9eD_Hj7qZzw",
  }
};
