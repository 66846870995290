import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { LocalStorageService, LocalStorage } from 'ngx-webstorage';

import { appConfig } from '../../app.config';

@Component({
  selector: 'app-app-discover',
  templateUrl: './app-discover.component.html',
  styleUrls: ['./app-discover.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class AppDiscoverComponent implements OnInit {
  private appConfig = appConfig;

  private devPath = '';
  private devMode: boolean = appConfig.devMode;

  @LocalStorage() public currentUser;
  @LocalStorage() public lang;

  constructor(
    private _storage: LocalStorageService,
    public _activeRouter: ActivatedRoute,
    public _router: Router
  ) {

    if (this.devMode) this.devPath = appConfig.devUrl;
  }

  ngOnInit() {
  }

}
