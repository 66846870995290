import { Component, OnInit, ViewEncapsulation, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import swal from 'sweetalert2';

import { LocalStorageService, LocalStorage } from 'ngx-webstorage';

import { appConfig } from '../../app.config';

@Component({
  selector: 'app-front-signin',
  templateUrl: './front-signin.component.html',
  styleUrls: ['./front-signin.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class FrontSigninComponent implements OnInit {
  private appConfig = appConfig;

  private devPath = '';
  private devMode: boolean = appConfig.devMode;

  @LocalStorage() public currentUser;
  @LocalStorage() public lang;

  dataFormGroup;
  dataForm;

  constructor(
    private _userService: UserService,
    private _storage: LocalStorageService,
    public _activeRouter: ActivatedRoute,
    public _router: Router
  ) {

    if (this.devMode) this.devPath = appConfig.devUrl;

    this.dataForm = {
      email: '',
      password: '',
    }
  }

  ngOnInit() {
    this.dataFormGroup = new FormGroup({
      email: new FormControl('', [
        Validators.email,
        Validators.required,
      ]),
      password: new FormControl('', [
        Validators.required,
      ]),
    });
  }

  toLoginEvent() {
    let vaildatorControls = ['email', 'password'];
    if (this.checkValidators(vaildatorControls)) {
      this._userService.authEmailSignin({
        'email': this.dataForm.email,
        'password': this.dataForm.password,
      }).subscribe((response) => {
        if (response.status == 200 && response.code == 20003) {
          // 紀錄Token
          this._storage.store('currentUser', response.currentUser);
          this._storage.observe('currentUser').subscribe();
          this._router.navigate(['/discover']);
        } else {
          swal({
            title: 'Error 404',
            text: response.message,
            type: 'error',
            confirmButtonText: 'Try again'
          });
        }
      });
    }
  }

  checkValidators(vaildatorControls) {
    let pass = true;
    vaildatorControls.forEach(element => {
      if (this.dataFormGroup.controls[element]['status'] == 'INVALID') {
        pass = false;
      } else {
        this.dataForm[element] = this.dataFormGroup.controls[element]['value'];
      }
    });
    return pass;
  }

}
