import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { appConfig } from '../app/app.config';

/**
 * Material樣板套件
 * https://material.angular.io/guide/getting-started
 */

/**
 * Angular Google Maps
 * https://angular-maps.com/guides/getting-started/
 */
import { AgmCoreModule } from '@agm/core';

/**
 * 網頁參數緩存模組
 */
import { Ng2Webstorage } from 'ngx-webstorage';
/**
 * 路徑模組
 */
import { routes } from './app.routers';

/**
 * Angular Material
 * https://material.angular.io/components/menu/overview
 */
import {
  MatMenuModule,
  MatInputModule,
  MatSelectModule,

  MatNativeDateModule,
  MatDatepickerModule
} from '@angular/material';
/**
 * 多國語系模組
 * ngx-translate/core: https://github.com/ngx-translate/core
 * 
 */
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/**
 * 彈出視窗模組
 * ngx-sweetalert2: https://github.com/sweetalert2/ngx-sweetalert2
 * 
 */
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
/**
 * 顯示圖像
 * ngx-image-gallery: https://www.npmjs.com/package/ngx-image-gallery
 */
import { NgxImageGalleryModule } from 'ngx-image-gallery';
/**
 * Guards
 */
import { AuthGuardService } from './_guards/auth-guard.service';
/**
 * Helper
 */
import { JwtInterceptorService } from './_helpers/jwt-interceptor.service';
/**
 * Models
 */
import { NgxMwGoogleMapsComponent } from './_models/ngx-mw-google-maps/ngx-mw-google-maps.component';
import { NgxMwCommentModelsComponent } from './_models/ngx-mw-comment-models/ngx-mw-comment-models.component';
import { NgxMwImageGalleryComponent } from './_models/ngx-mw-image-gallery/ngx-mw-image-gallery.component';
/**
 * Service
 */
import { UserService } from './_services/user.service';
import { PlaceService } from './_services/place.service';
import { TypeService } from './_services/type.service';
import { FavoriteService } from './_services/favorite.service';
import { CollectService } from './_services/collect.service';
import { StarService } from './_services/star.service';
/**
 * Pipe
 */
import { DatetimeTranslatePipe } from './_pipes/datetime-translate.pipe';
/**
 * 頁面開始
 */
import { AppComponent } from './app.component';
import { FrontIndexComponent } from './_pages/front-index/front-index.component';
import { FrontSigninComponent } from './_pages/front-signin/front-signin.component';
import { FrontSignupComponent } from './_pages/front-signup/front-signup.component';
import { FrontPrivacyComponent } from './_pages/front-privacy/front-privacy.component';
import { AppHeaderComponent } from './_pages/app-header/app-header.component';
import { AppMenuComponent } from './_pages/app-menu/app-menu.component';
import { AppDiscoverComponent } from './_pages/app-discover/app-discover.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    FrontIndexComponent,
    FrontSigninComponent,
    FrontSignupComponent,
    FrontPrivacyComponent,
    
    AppHeaderComponent,
    AppMenuComponent,
    AppDiscoverComponent,

    NgxMwGoogleMapsComponent,
    NgxMwImageGalleryComponent,

    DatetimeTranslatePipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    
    MatNativeDateModule,
    MatDatepickerModule,

    HttpClientModule,
    Ng2Webstorage,

    AgmCoreModule.forRoot({
      apiKey: appConfig.google.mapsApiKey
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
    }),

    SweetAlert2Module.forRoot(),

    // NgxImageGalleryModule,

    routes,
    
  ],
  exports: [
    BrowserModule,
  ],
  providers: [
    AuthGuardService,
    UserService,
    PlaceService,
    TypeService,
    FavoriteService,
    CollectService,
    StarService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
