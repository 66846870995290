import { Component, OnInit, ViewEncapsulation, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import swal from 'sweetalert2';

import { LocalStorageService, LocalStorage } from 'ngx-webstorage';

import { appConfig } from '../../app.config';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';

const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-front-signup',
  templateUrl: './front-signup.component.html',
  styleUrls: ['./front-signup.component.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    UserService,
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class FrontSignupComponent implements OnInit {
  private appConfig = appConfig;

  private devPath = '';
  private devMode: boolean = appConfig.devMode;

  @LocalStorage() public currentUser;
  @LocalStorage() public lang;

  dataFormGroup;
  dataForm;
  step;

  constructor(
    private _userService: UserService,
    private _storage: LocalStorageService,
    public _activeRouter: ActivatedRoute,
    public _router: Router
  ) {

    if (this.devMode) this.devPath = appConfig.devUrl;

    this.dataForm = {
      email: '',
      password: '',
      confirm_password: '',
      avatar: 'assets/img/default.png',
      name: 'username',
      gender: 0,
      birthday: '1993/01/04',
      lang: this.lang
    };

    this.step = 1;
  }

  ngOnInit() {
    this.dataFormGroup = new FormGroup({
      email: new FormControl('', [
        Validators.email,
        Validators.required,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(24),
      ]),
      confirm_password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(24),
        confirmPasswordValidators,
      ]),
      name: new FormControl('', [
        Validators.required,
      ]),
      gender: new FormControl('', [
        Validators.required,
      ]),
      birthday: new FormControl('', [
        Validators.required,
      ]),
    });
  }

  checkValidators(vaildatorControls) {
    let pass = true;
    vaildatorControls.forEach(element => {
      if (this.dataFormGroup.controls[element]['status'] == 'INVALID') {
        pass = false;
      } else {
        this.dataForm[element] = this.dataFormGroup.controls[element]['value'];
      }
    });
    return pass;
  }

  uploadAvatarImage(avatar) {
    let formFileData = new FormData();
    const file = avatar.target.files[0];
    
    formFileData.append('image', file);
    
    this._userService.userUploadAvatar(formFileData)
      .subscribe((response) => {
      if (response.status == 200 && response.data.image) {
        this.dataForm.avatar = response.data.image;
      }
    })
  }

  NextBtn02() {
    let vaildatorControls = ['email', 'password', 'confirm_password'];
    if (this.checkValidators(vaildatorControls)) {
      this._userService.authExistEmail({
        'email': this.dataForm.email
      }).subscribe((response) => {
        if (response.status == 200 && response.code == 20008) {
          this.dataFormGroup.controls['email'].setErrors({'exist': true});
        } else {
          this.step = 3;
        }
      });
    }
  }

  NextBtn03() {
    let vaildatorControls = ['name', 'gender', 'birthday'];
    if (this.checkValidators(vaildatorControls)) {
      this.dataForm.birthday = moment(this.dataForm.birthday).format('YYYY/MM/DD');
      this._userService.authEmailSignup(this.dataForm)
        .subscribe((response) => {
          if (response.status == 200) {
            // 紀錄Token
            this._storage.store('currentUser', response.currentUser);
            this._storage.observe('currentUser').subscribe();
            this.step = 4;
          } else {
            swal({
              title: 'Error 404',
              text: response.message,
              type: 'error',
              confirmButtonText: 'Try again'
            });
          }
        });
    }
  }

  NextBtn04() {
    // 用戶已登入
    this._router.navigate(['/discover']);
  }
  
}

function confirmPasswordValidators(control: FormControl) {
  const group = control.root;
  if (group['controls']) {
    const password = group['controls']['password']['value'];
    const confirm_password = group['controls']['confirm_password']['value'];
    if (password !== confirm_password) {
      return {
        confirm: true
      };
    }
  }
  return null;
}
