import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-front-privacy',
  templateUrl: './front-privacy.component.html',
  styleUrls: ['./front-privacy.component.sass']
})
export class FrontPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
