import { Component, ElementRef, ViewEncapsulation, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { appConfig } from './app.config';

/**
 * 負責緩存
 */
import {
  LocalStorage, SessionStorage,
  LocalStorageService, SessionStorageService
} from 'ngx-webstorage';

/**
 * 多國語系使用
 */
import { TranslateService } from '@ngx-translate/core';

/**
 * 事件保證消除不會重複
 */

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private appConfig = appConfig;

  private devPath = '';
  private devMode: boolean = appConfig.devMode;

  @LocalStorage() public lang;

  constructor(private _elRef: ElementRef,
    private translate: TranslateService,
    private _storage: LocalStorageService,
    private _change: ChangeDetectorRef,
    public _activeRouter: ActivatedRoute,
    public _router: Router) {

    const that = $(this._elRef.nativeElement);
    const there = this;
  }

  ngOnInit() {

    const there = this;
    // 語系相關設定
    there.translate.addLangs(there.appConfig['lang']);
    let browserLang = there.translate.getBrowserCultureLang().toLowerCase();
    this._activeRouter.queryParams
      .subscribe(params => {
        // 確認語系 Check Language
        if (params['lang'] != undefined) {
          // Change the language
          // console.log(params['lang']);
          there.checkNowLanguage(params['lang'], function (lang) {
            there._storage.store('lang', lang);
            there._storage.observe('lang').subscribe((langValue) => {
              // change to assets i18n file format
              // console.log(lang);
              there.translate.use(langValue);
              there._change.detectChanges();
            });
          });
        } else {
          if (there.lang != undefined) {
            // change to assets i18n file format
            there.translate.setDefaultLang(there.lang);
            there.translate.use(there.lang);
            there._change.detectChanges();
          } else {
            there._storage.store('lang', there.appConfig['langDefault']);
            there._storage.observe('lang').subscribe((langValue) => {
              // change to assets i18n file format
              there.translate.setDefaultLang(there.appConfig['langDefault']);
              there._change.detectChanges();
            });
          }
        }
      });
  }

  // 確認當前的語言
  checkNowLanguage(lang, callback) {
    var runOut = false;
    var langList = this.appConfig.lang;
    for (var i = 0; i < langList.length; ++i) {
      // code...
      if (lang == langList[i]) {
        runOut = true;
      }
    }

    if (runOut) {
      callback(lang);
    } else {
      callback(this.appConfig['langDefault']);
    }
  }
}
