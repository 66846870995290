import {
  Component, OnInit, ViewEncapsulation, EventEmitter,
  ViewChild, ElementRef, Input, Output, 
  ChangeDetectorRef, ChangeDetectionStrategy
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PlaceService } from '../../_services/place.service';
import { TypeService } from '../../_services/type.service';
import { FavoriteService } from '../../_services/favorite.service';
import { CollectService } from '../../_services/collect.service';
import { StarService } from '../../_services/star.service';

import { LocalStorageService, LocalStorage } from 'ngx-webstorage';

import { appConfig } from '../../app.config';

import { TranslateService } from '@ngx-translate/core';

import { NgxMwImageGalleryComponent } from "../ngx-mw-image-gallery/ngx-mw-image-gallery.component";
import { GALLERY_CONF, GALLERY_IMAGE } from "../ngx-mw-image-gallery/ngx-mw-image-gallery.conf";

declare var $: any;

import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes,
  query,
  stagger,
} from '@angular/animations';
import { swalDefaultsProvider } from '../../../../node_modules/@toverux/ngx-sweetalert2/di';
import swal from 'sweetalert2';

declare const google: any;

declare var require: any;

var MarkerWithLabel = require('markerwithlabel')(google.maps);

@Component({
  selector: 'app-ngx-mw-google-maps',
  templateUrl: './ngx-mw-google-maps.component.html',
  styleUrls: ['./ngx-mw-google-maps.component.sass'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // trigger('sideboxAnimation', [
    //   state('inactive', style({
    //     left: '-380px',
    //   })),
    //   state('active', style({
    //     left: '0',
    //   })),
    //   transition('* => *', animate('300ms ease-in-out')),
    // ]),

    trigger('sideCommentAnimation', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), { optional: true }),
  
        query(':enter', stagger('300ms', [
          animate('0.5s ease-in-out', keyframes([
            style({ opacity: 0, transform: 'translateY(-75px)', offset: 0 }),
            style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
          ]))
        ]), { optional: true }),
  
        query(':leave', stagger('300ms', [
          animate('0.5s ease-in-out', keyframes([
            style({ opacity: 0, transform: 'translateY(0)', offset: 0 }),
            style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(75px)', offset: 1 }),
          ]))
        ]), { optional: true }),
      ]),
    ]),
  ]
})
export class NgxMwGoogleMapsComponent implements OnInit {
  private appConfig = appConfig;

  private devPath = '';
  private devMode: boolean = appConfig.devMode;

  private sideNav = 1;
  public sideView = '';

  // 分類
  placeTypes = [];

  // 目前評分的星星數
  commentStar = 5;

  // 景點參數
  placesData = {
    id: '', // 景點編號
    name: '', // 名稱
    type: 'mw_base', // 分類
    score: 5.0, // 分數
    scoreGoogle: 0, // Google 分數
    tags: ['a', 'b'], // 標記
    photos: [], // 相片
    addr: '', // 地址
    phone: [], // 電話
    open: {
      mon: {
        start: "",
        end: "",
      },
      tue: {
        start: "",
        end: "",
      },
      wed: {
        start: "",
        end: "",
      },
      thu: {
        start: "",
        end: "",
      },
      fri: {
        start: "",
        end: "",
      },
      sat: {
        start: "",
        end: "",
      },
      sun: {
        start: "",
        end: "",
      },
    }, // 營業時間
    payment: [], // 付款方式
    creditCard: false, // 是否信用卡支付
    creator: 'Google', // 創建者
    commentsCount: 0, // 回應人數
    commented: false, // 是否回應
    photosCount: 0, // 照片數量
    likesCount: 0, // 喜歡人數
    liked: false, // 是否喜歡
    collectCount: 0, // 收藏人數
    collected: false, // 是否收藏
    commentList: [], // 別人的評論
    commentSelf: {}, // 自己的評論
  }

  commentEdit = {};

  /**
   * 景點評論
   * {
   *    userId: {
   *      displayName: "",
   *      avatar: "",
   *    },
   *    placesId: "",
   *    score: 5,
   *    comment: "",
   *    photos: "",
   *    likesCount: 0,
   *    liked: true,
   *    liker: [],
   *    createdAt: 1435892309215,
   *    updatedAt: 1435892309215,
   * }
   */
  commentList = [];

  @LocalStorage() public currentUser;
  @LocalStorage() public lang;

  @Output() public markerClick: EventEmitter<any> = new EventEmitter();

  @ViewChild('map') private mapElement: ElementRef;

  private mapInstance: any; // 地圖物件
  private mapOptions = { // 地圖設定
    zoom: 18,
    center: new google.maps.LatLng(24.107101, 120.688945),
    panControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    overviewMapControl: false,
    zoomControl: true,
    zoomControlOptions: {
      style: google.maps.ZoomControlStyle.SMALL,
      position: google.maps.ControlPosition.RIGHT_CENTER
    },
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    fullscreenControl: false,
  };
  private mapsMarkerList = []; // 景點資料
  private mapsMarkerCluster = null; // 景點集合
  private mcOptions = { // 集合造型
    gridSize: 80,
    styles: [
      { textColor: '#fff', textSize: '18', url: '/assets/img/cluster/1.png', width: '100', height: '100' },
      { textColor: '#fff', textSize: '18', url: '/assets/img/cluster/2.png', width: '100', height: '100' },
      { textColor: '#fff', textSize: '18', url: '/assets/img/cluster/3.png', width: '100', height: '100' },
      { textColor: '#fff', textSize: '18', url: '/assets/img/cluster/4.png', width: '100', height: '100' }
    ],
    maxZoom: 15
  };
  private mapsLoadMarker = false;
  private dragendTimer = null;
  private dragend = 0;

  private mapStyles = [ // 地圖風格
    {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
        { "color": "#82CCFF" }
      ]
    }, {
      "featureType": "landscape.natural.landcover",
      "elementType": "geometry.fill",
      "stylers": [
        { "color": "#F0EDE5" }
      ]
    }, {
      "featureType": "landscape.natural.terrain",
      "stylers": [
        { "visibility": "off" }
      ]
    }, {
      "featureType": "road.highway",
      "stylers": [
        { "visibility": "simplified" }
      ]
    }, {
      "featureType": "administrative.locality"
    }, {
      "featureType": "road.highway",
      "stylers": [
        { "visibility": "simplified" }
      ]
    }, {
      "featureType": "road.arterial",
      "stylers": [
        { "visibility": "simplified" }
      ]
    }, {
      "featureType": "administrative.country"
    }, {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [
        { "visibility": "off" }
      ]
    }
  ];

  private toast = (swal as any).mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });

  // get reference to gallery component
  @ViewChild('ngxImageGallery') ngxImageGallery: NgxMwImageGalleryComponent;
  // gallery configuration
  galleryConf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showCloseControl: true,
    showImageTitle: false,
    inline: false,
    backdropColor: 'rgba(13,13,14,0.85)'
  };
  // gallery images
  galleryImages: GALLERY_IMAGE[] = [
    // {
    //   url: "https://images.pexels.com/photos/669013/pexels-photo-669013.jpeg?w=1260",
    //   altText: 'woman-in-black-blazer-holding-blue-cup',
    //   title: 'woman-in-black-blazer-holding-blue-cup',
    //   thumbnailUrl: "https://images.pexels.com/photos/669013/pexels-photo-669013.jpeg?w=60"
    // },
    // {
    //   url: "https://images.pexels.com/photos/669006/pexels-photo-669006.jpeg?w=1260",
    //   altText: 'two-woman-standing-on-the-ground-and-staring-at-the-mountain',
    //   extUrl: 'https://www.pexels.com/photo/two-woman-standing-on-the-ground-and-staring-at-the-mountain-669006/',
    //   thumbnailUrl: "https://images.pexels.com/photos/669006/pexels-photo-669006.jpeg?w=60"
    // },
  ];

  constructor(private _elRef: ElementRef,

    private _placeService: PlaceService,
    private _typeService: TypeService,
    private _favoriteService: FavoriteService,
    private _collectService: CollectService,
    private _starService: StarService,

    private _storage: LocalStorageService,

    public _activeRouter: ActivatedRoute,
    public _router: Router,

    private _change: ChangeDetectorRef,

    private _translate: TranslateService,
  ) {

    const that = $(this._elRef.nativeElement);
    if (this.devMode) this.devPath = appConfig.devUrl;
  }

  ngOnInit() {
    // 載入分類清單
    this._typeService.getList({}).subscribe((response) => {
      if (response.code === 20005) {
        const splitLang = this.lang.split('-');
        response.results.forEach(element => {
          this.placeTypes[element.name] = element['lang'][splitLang[0] + '_' + splitLang[1]];
        });
      }
    });
  }

  ngAfterViewInit() {
    const that = this;
    // 導入地圖元素
    this.mapInstance = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);
    google.maps.event.addListenerOnce(this.mapInstance, 'idle', function () {
      // do something only the first time the map is loaded
      that.initMaps();
    });

  }

  // 地圖初始化
  private initMaps() {
    this.mapInstance.setOptions({ styles: this.mapStyles });

    this.initMapEvents();
  }

  // 事件初始化
  private initMapEvents() {
    setTimeout(() => {
      // 地點初始化
      this.mwInitMarker();
      // 移動初始化
      this.addDragToMarker();
    }, 1000);
  }

  // 移動地圖座標搜尋
  private addDragToMarker() {
    const that = this;
    google.maps.event.addListener(that.mapInstance, "dragend", (e) => {
      that.dragend++; //count dragend
      if (that.dragend === 1) {
        that.dragendTimer = setTimeout(function() {
          that.dragend = 0;
          // 確認是否正在搜尋
          if (that.mapsLoadMarker === false) {
            that.mapsLoadMarker = true; // 搜尋中
            that.mwInitMarker(); // 初始化
          }
        }, 1000);
      } else {
        that.dragend = 0;
        clearTimeout(that.dragendTimer);
      }
    });
  }

  // 初始化地圖
  private mwInitMarker() {
    let that = this;
    const lat0 = that.mapInstance.getBounds().getNorthEast().lat();
    const lng0 = that.mapInstance.getBounds().getNorthEast().lng();
    const lat1 = that.mapInstance.getBounds().getSouthWest().lat();
    const lng1 = that.mapInstance.getBounds().getSouthWest().lng();
    const lat = that.mapInstance.getCenter().lat();
    const lng = that.mapInstance.getCenter().lng();
    const zoomLevel = that.mapInstance.getZoom();
    that.clearMapMarker();
    // load marker
    /*
      Level 7~   x * 0.1
      Level 8     x * 0.2
      Level 9     x * 0.3
      Level 10     x * 0.4
      Level 11    x * 0.5
      Level 12     x * 0.6
      Level 13     x * 0.8
      Level 14     x * 0.9
      Level 15~     x * 1
    */
    that._placeService.getNearbyPlaces({
      lat: lat,
      lng: lng,
      lat0: lat0,
      lng0: lng0,
      lat1: lat1,
      lng1: lng1,
      zoomLevel: zoomLevel,
    }).subscribe((response) => {
      response.results.forEach(element => {
        let marker;

        marker = new MarkerWithLabel({
          position: new google.maps.LatLng(element.loc.coordinates[1], element.loc.coordinates[0]),
          map: that.mapInstance,
          zIndex: 100,
          icon: 'http://www.mapswalker.com/assets/img/view//64/' + element.type + '.png',
          labelAnchor: new google.maps.Point(5, 75),
          labelClass: 'mw-marker mw-marker-' + element._id,
          labelZIndex: 99,
          title: element.name,
          vmData: element,
        });
        // map now
        marker.setMap(that.mapInstance);

        that.mapsMarkerList.push(marker);

        google.maps.event.addListener(marker, "click", function (e) {
          const thisMarker = this;
          // 移動位置至中心
          that.mapInstance.panTo(thisMarker.position);
          // 設定資料
          that.setVMData(thisMarker.vmData);
          // 打開側欄
          that.clickSideBoxAnimate('open');
          // 更新導覽列至總覽
          that.sideNav = 1;
          // 強制更新
          that._change.detectChanges();
        });
      });
      // 取消載入
      that.mapsLoadMarker = false;
    });

  }

  // 設定景點資料
  private setVMData(vmData) {
    this.placesData.id = vmData['_id'];
    this.placesData.name = vmData['name'];
    this.placesData.type = vmData['type'];
    // Google Score
    if (vmData['score'] == 0 && vmData['scoreGoogle'] != 0) {
      this.placesData.score = vmData['scoreGoogle'];
    } else if (vmData['score'] != 0 && vmData['scoreGoogle'] == 0) {
      this.placesData.score = vmData['score'];
    } else {
      this.placesData.score = parseFloat(((vmData['score'] + vmData['scoreGoogle'])/2).toFixed(1));
    }
    this.placesData.scoreGoogle = parseFloat(vmData['scoreGoogle']);
    this.placesData.tags = vmData['tags'];
    this.placesData.photos = vmData['photo'];
    this.placesData.addr = vmData['addr'];
    this.placesData.phone = vmData['phone'];
    this.placesData.open = vmData['open'];
    this.placesData.payment = vmData['payment'];
    this.placesData.creditCard = vmData['creditCard'];
    this.placesData.creator = vmData['creator'];
    this.placesData.commentsCount = vmData['commentsCount'];
    this.placesData.photosCount = vmData['photosCount'];
    this.placesData.likesCount = vmData['likesCount'];
    this.placesData.collectCount = vmData['collectCount'];

    this.placesData.commented = vmData['commented'];
    this.placesData.liked = vmData['liked'];
    this.placesData.collected = vmData['collected'];

    // 取得當前景點的評論
    this.getCommentList(vmData['_id']);
  }

  // 清除地圖標記
  private clearMapMarker() {
    for (let i = 0; i < this.mapsMarkerList.length; i++) {
      this.mapsMarkerList[i].unbind('click');
      this.mapsMarkerList[i].setMap(null);
    }

    if (this.mapsMarkerCluster) {
      this.mapsMarkerCluster.clearMarkers();
    }

    this.mapsMarkerList.length = 0;
  }

  // 點選喜歡
  private clickTheFavorite(placesId) {
    const that = this;
    that._favoriteService.toggleFavorite({
      placesId: placesId
    }).subscribe((response) => {
      if (response.code === 20005 && response.status === 200) {
        // 修改當前側欄資訊狀況
        if (that.placesData.liked === true) {
          that.placesData.liked = false;
          that.placesData.likesCount -= 1;
        } else {
          that.placesData.liked = true;
          that.placesData.likesCount += 1;
        }
        // 修改地圖上的Maker
        that.mapsMarkerList.forEach((element, index) => {
          if (element.vmData._id == placesId) {
            that.mapsMarkerList[index]['vmData']['likesCount'] = that.placesData.likesCount;
            that.mapsMarkerList[index]['vmData']['liked'] = that.placesData.liked;
          }
        });

        // 強制更新
        this._change.detectChanges();
      }
    });
  }

  // 點選收藏
  private clickTheCollect(placesId) {
    const that = this;
    that._collectService.toggleCollect({
      placesId: placesId
    }).subscribe((response) => {
      if (response.code === 20005 && response.status === 200) {
        // 修改當前側欄資訊狀況
        if (that.placesData.collected === true) {
          that.placesData.collected = false;
          that.placesData.collectCount -= 1;
        } else {
          that.placesData.collected = true;
          that.placesData.collectCount += 1;
        }
        // 修改地圖上的Maker
        that.mapsMarkerList.forEach((element, index) => {
          if (element.vmData._id == placesId) {
            that.mapsMarkerList[index]['vmData']['collectCount'] = that.placesData.collectCount;
            that.mapsMarkerList[index]['vmData']['collected'] = that.placesData.collected;
          }
        });

        // 強制更新
        this._change.detectChanges();
      }
    });
  }

  // 點選評論的喜歡
  private clickCommentFavorite(starId) {
    const that = this;
    const placesId = that.placesData.id;
    that._starService.toggleFavorite({
      starId: starId
    }).subscribe((response) => {
      if (response.code === 20005 && response.status === 200) {
        /**
         * Self
         */
        if (that.placesData.commentSelf['_id'] === starId) {
          // 修改當前側欄資訊狀況
          if (that.placesData.commentSelf['liked'] === true) {
            that.placesData.commentSelf['liked'] = false;
            that.placesData.commentSelf['likesCount'] -= 1;
          } else {
            that.placesData.commentSelf['liked'] = true;
            that.placesData.commentSelf['likesCount'] += 1;
          }
          // 因為評論是點選後才會進行抓取，所以不用特別修改地圖上的內容。
        }

        /**
         * Comment
         */
        that.placesData.commentList.forEach((element, index) => {
          if (that.placesData.commentList[index]['_id'] === starId) {
            // 修改當前側欄資訊狀況
            if (that.placesData.commentList[index]['liked'] === true) {
              that.placesData.commentList[index]['liked'] = false;
              that.placesData.commentList[index]['likesCount'] -= 1;
            } else {
              that.placesData.commentList[index]['liked'] = true;
              that.placesData.commentList[index]['likesCount'] += 1;
            }
            // 因為評論是點選後才會進行抓取，所以不用特別修改地圖上的內容。
          }
        });

        // 強制更新
        this._change.detectChanges();
      }
    });
  }

  // 顯示撰寫評論的視窗
  private showTheCommentModels() {
    const that = this;
    (swal as any).mixin({
      confirmButtonText: 'Next',
      showCancelButton: true,
      progressSteps: ['1', '2', '3'],
    }).queue([
      {
        title: 'Like',
        html: 
          that.getTranslateData('DISCOVER.COMMENT_DO_YOU_LIKE') +
          '<ul class="mws-star-list-comment">' +
          '  <li class="mws-star-item-comment mws-star-item-comment-1" (click)="commentStar = 1">' +
          '    <i class="icon-star" [class.icon-star]="commentStar >= 1" [class.icon-star-empty]="commentStar < 1"></i>' +
          '  </li>' +
          '  <li class="mws-star-item-comment mws-star-item-comment-2" (click)="commentStar = 2">' +
          '    <i class="icon-star" [class.icon-star]="commentStar >= 2" [class.icon-star-empty]="commentStar < 2 "></i>' +
          '  </li>' +
          '  <li class="mws-star-item-comment mws-star-item-comment-3" (click)="commentStar = 3">' +
          '    <i class="icon-star" [class.icon-star]="commentStar >= 3" [class.icon-star-empty]="commentStar < 3 "></i>' +
          '  </li>' +
          '  <li class="mws-star-item-comment mws-star-item-comment-4" (click)="commentStar = 4">' +
          '    <i class="icon-star" [class.icon-star]="commentStar >= 4" [class.icon-star-empty]="commentStar < 4 "></i>' +
          '  </li>' +
          '  <li class="mws-star-item-comment mws-star-item-comment-5" (click)="commentStar = 5">' +
          '    <i class="icon-star" [class.icon-star]="commentStar >= 5" [class.icon-star-empty]="commentStar < 5 "></i>' +
          '  </li>' +
          '</ul>',
        onOpen: (val) => {
          that._elRef.nativeElement.closest('body').querySelector('.mws-star-item-comment-1').addEventListener('click', (event) => {
            that.commentStar = 1;
            that.changeCommentStar(1);
          });
          that._elRef.nativeElement.closest('body').querySelector('.mws-star-item-comment-2').addEventListener('click', (event) => {
            that.commentStar = 2;
            that.changeCommentStar(2);
          });
          that._elRef.nativeElement.closest('body').querySelector('.mws-star-item-comment-3').addEventListener('click', (event) => {
            that.commentStar = 3;
            that.changeCommentStar(3);
          });
          that._elRef.nativeElement.closest('body').querySelector('.mws-star-item-comment-4').addEventListener('click', (event) => {
            that.commentStar = 4;
            that.changeCommentStar(4);
          });
          that._elRef.nativeElement.closest('body').querySelector('.mws-star-item-comment-5').addEventListener('click', (event) => {
            that.commentStar = 5;
            that.changeCommentStar(5);
          });
        },
        focusConfirm: false,
        preConfirm: () => {
          return this.commentStar;
        }
      },
      {
        title: 'Comment',
        text: that.getTranslateData('DISCOVER.COMMENT_WHAT_DO_YOU_THINK'),
        input: 'textarea',
      },
      {
        title: 'Category',
        text: that.getTranslateData('DISCOVER.COMMENT_WHAT_TYPE'),
        input: 'select',
        inputValue: 'mw_base',
        inputOptions: this.placeTypes,
        inputPlaceholder: that.getTranslateData('DISCOVER.CHOOSE_TYPE'),
      }
    ]).then((result) => {
      if (result.value) {
        const resultJson = result.value;
        const dataQuery = {};
        dataQuery['star'] = resultJson[0];
        dataQuery['comment'] = resultJson[1];
        dataQuery['vote_type'] = resultJson[2];
        dataQuery['placesId'] = that.placesData.id;
        that._starService.writeStar(dataQuery)
          .subscribe((response) => {
            if (response.code === 20005 && response.status === 200) {
              that.placesData.commented = true;
              that.placesData.commentsCount += 1;
              that.placesData.type = response.type;
              that.placesData.score = parseFloat(((response.score + that.placesData.scoreGoogle) / 2).toFixed(1));
              // 修改地圖上的Maker
              that.mapsMarkerList.forEach((element, index) => {
                if (element.vmData._id == that.placesData.id) {
                  that.mapsMarkerList[index]['vmData']['commentsCount'] = that.placesData.commentsCount;
                  that.mapsMarkerList[index]['vmData']['commented'] = that.placesData.commented;
                  that.mapsMarkerList[index]['vmData']['type'] = that.placesData.type;
                  that.mapsMarkerList[index]['vmData']['score'] = parseFloat(((that.placesData.score + that.placesData.scoreGoogle) / 2).toFixed(1));
                  // 強制更新
                  that._change.detectChanges();
                }
              });
              that.toast({
                type: 'success',
                title: response.message
              });
            } else {
              that.toast({
                type: 'error',
                title: response.message
              });
            }
        });
      }
    });
  }

  // 上傳圖片
  private uploadAvatarImage(avatar) {
    const that = this;
    let formFileData = new FormData();
    const files = avatar.target.files;

    formFileData.append('placesId', that.placesData.id);

    for (let index = 0; index < files.length; index++) {
      formFileData.append('photos', files[index]);
      
    }

    that._starService.uploadImage(formFileData)
      .subscribe((response) => {
        if (response.status == 200 && response.photos && response.code == 20005) {
          const resPhoto = response.photos;
          for (let index = 0; index < resPhoto.length; index++) {
            if (resPhoto[index] != undefined) {
              // that.placesData.photos.push(resPhoto[index]);
              that.placesData.photosCount += 1;
              // 修改地圖上的Maker
              that.mapsMarkerList.forEach((element, keyMaker) => {
                if (element.vmData._id == that.placesData.id) {
                  that.mapsMarkerList[keyMaker]['vmData']['photosCount'] += 1;
                  that.mapsMarkerList[keyMaker]['vmData']['photo'].push(resPhoto[index]);
                }
              });
            }
          }
        }
      });
  }

  // 取得當前景點的評論
  private getCommentList(id) {
    const that = this;
    const filterQuery = {
      'placesId': id
    };
    that._starService.getList(filterQuery)
      .subscribe((response) => {
        if (response.once) {
          // 自己的評論
          that.placesData.commentSelf = response.once;
        } else {
          that.placesData.commentSelf = {};
        }

        if (response.list) {
          // 別人的評論
          that.placesData.commentList = response.list;
        } else {
          that.placesData.commentList = [];
        }
    });
  }

  // 取得語言內容
  private getTranslateData(name) {
    return this._translate.get(name)['value'];
  }

  // 取得分類
  private getPlacesType(type) {
    if (this.placeTypes) {
      return this.placeTypes[type];
    }
  }

  // 點選評論星星事件
  private changeCommentStar(num) {
    // 初始化
    $('.mws-star-item-comment i').attr('class', 'icon-star-empty');
    for (let index = 0; index < num; index++) {
      $('.mws-star-item-comment-' + (index+1) + ' i').attr('class', 'icon-star');
    }
  }
  
  // 開啟側欄
  private clickSideBoxAnimate(type = '') {
    if (type === '') {
      if (this.sideView === 'inactive' || this.sideView === '') {
        this.sideView = 'active';
      } else {
        this.sideView = 'inactive';
      }
    } else {
      if (type === 'open') {
        this.sideView = 'active';
      } else {
        this.sideView = 'inactive';
      }
    }
  }

  // 檢查圖片最高數量
  private checkPhotoIndex(index) {
    if (index >= 0 && index <= 4) {
      return true;
    } else {
      return false;
    }
  }

  // 設定圖片
  private setViewImage(urlPath, photos) {
    const that = this;
    that.galleryImages = [];
    photos.forEach(element => {
      that.galleryImages.push({
        'url': urlPath + element,
        'thumbnailUrl': urlPath + element,
      });
    });
    // 強制更新
    this._change.detectChanges();
  }

  // 修改評論
  private editCommit(starId) {
    const that = this;
    const filterQuery = {
      'starId': starId
    };
    that._starService.getOnce(filterQuery)
      .subscribe((response) => {
        if (response.code === 20005) {
          // 自己的評論
          that.commentEdit = response;
          (swal as any).mixin({
            confirmButtonText: 'Next',
            showCancelButton: true,
            progressSteps: ['1', '2'],
          }).queue([
            {
              title: 'Like',
              html:
                that.getTranslateData('DISCOVER.COMMENT_DO_YOU_LIKE') +
                '<ul class="mws-star-list-comment">' +
                '  <li class="mws-star-item-comment mws-star-item-comment-1" (click)="commentStar = 1">' +
                '    <i class="icon-star" [class.icon-star]="commentStar >= 1" [class.icon-star-empty]="commentStar < 1"></i>' +
                '  </li>' +
                '  <li class="mws-star-item-comment mws-star-item-comment-2" (click)="commentStar = 2">' +
                '    <i class="icon-star" [class.icon-star]="commentStar >= 2" [class.icon-star-empty]="commentStar < 2 "></i>' +
                '  </li>' +
                '  <li class="mws-star-item-comment mws-star-item-comment-3" (click)="commentStar = 3">' +
                '    <i class="icon-star" [class.icon-star]="commentStar >= 3" [class.icon-star-empty]="commentStar < 3 "></i>' +
                '  </li>' +
                '  <li class="mws-star-item-comment mws-star-item-comment-4" (click)="commentStar = 4">' +
                '    <i class="icon-star" [class.icon-star]="commentStar >= 4" [class.icon-star-empty]="commentStar < 4 "></i>' +
                '  </li>' +
                '  <li class="mws-star-item-comment mws-star-item-comment-5" (click)="commentStar = 5">' +
                '    <i class="icon-star" [class.icon-star]="commentStar >= 5" [class.icon-star-empty]="commentStar < 5 "></i>' +
                '  </li>' +
                '</ul>',
              onOpen: (val) => {
                that._elRef.nativeElement.closest('body').querySelector('.mws-star-item-comment-1').addEventListener('click', (event) => {
                  that.commentStar = 1;
                  that.changeCommentStar(1);
                });
                that._elRef.nativeElement.closest('body').querySelector('.mws-star-item-comment-2').addEventListener('click', (event) => {
                  that.commentStar = 2;
                  that.changeCommentStar(2);
                });
                that._elRef.nativeElement.closest('body').querySelector('.mws-star-item-comment-3').addEventListener('click', (event) => {
                  that.commentStar = 3;
                  that.changeCommentStar(3);
                });
                that._elRef.nativeElement.closest('body').querySelector('.mws-star-item-comment-4').addEventListener('click', (event) => {
                  that.commentStar = 4;
                  that.changeCommentStar(4);
                });
                that._elRef.nativeElement.closest('body').querySelector('.mws-star-item-comment-5').addEventListener('click', (event) => {
                  that.commentStar = 5;
                  that.changeCommentStar(5);
                });
                that.changeCommentStar(response.data.score);
                that.commentStar = response.data.score;
              },
              focusConfirm: false,
              preConfirm: () => {
                return this.commentStar;
              }
            },
            {
              title: 'Comment',
              text: that.getTranslateData('DISCOVER.COMMENT_WHAT_DO_YOU_THINK'),
              input: 'textarea',
              inputValue: response.data.comment
            }
          ]).then((result) => {
            if (result.value) {
              const resultJson = result.value;
              const dataQuery = {};
              dataQuery['star'] = resultJson[0];
              dataQuery['comment'] = resultJson[1];
              dataQuery['starId'] = starId;
              dataQuery['placesId'] = that.placesData.id;
              that._starService.updateStar(dataQuery)
                .subscribe((response) => {
                  if (response.code === 20005 && response.status === 200) {
                    that.placesData.score = response.score;
                    // 修改地圖上的Maker
                    that.mapsMarkerList.forEach((element, index) => {
                      if (element.vmData._id == that.placesData.id) {
                        that.mapsMarkerList[index]['vmData']['score'] = response.score;
                        // 強制更新
                        that._change.detectChanges();
                      }
                    });
                    that.toast({
                      type: 'success',
                      title: response.message
                    });
                  } else {
                    that.toast({
                      type: 'error',
                      title: response.message
                    });
                  }
                });
            }
          })
        } else {
          swal({
            title: response.message
          });
        }
      });
  }
  
  // 檢舉評論
  private reportCommit(starId) {
    console.log(starId);
  }


  /**
   * 顯示圖像
   * ngx-image-gallery: https://www.npmjs.com/package/ngx-image-gallery
   */

  // METHODS
  // open gallery
  openGallery(index: number = 0) {
    this.ngxImageGallery.open(index);

    // 強制更新
    this._change.detectChanges();
  }

  // close gallery
  closeGallery() {
    this.ngxImageGallery.close();
  }

  // set new active(visible) image in gallery
  newImage(index: number = 0) {
    this.ngxImageGallery.setActiveImage(index);
  }

  // next image in gallery
  nextImage(index: number = 0) {
    this.ngxImageGallery.next();
  }

  // prev image in gallery
  prevImage(index: number = 0) {
    this.ngxImageGallery.prev();
  }

  /**************************************************/

  // EVENTS
  // callback on gallery opened
  galleryOpened(index) {
    // console.info('Gallery opened at index ', index);
  }
  
  // callback on gallery closed
  galleryClosed() {
    // console.info('Gallery closed.');
  }
  
  // callback on gallery image clicked
  galleryImageClicked(index) {
    // console.info('Gallery image clicked with index ', index);
  }

  // callback on gallery image changed
  galleryImageChanged(index) {
    // console.info('Gallery image changed to index ', index);
  }

}
