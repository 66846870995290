import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { appConfig } from '../app.config';

import { LocalStorageService, LocalStorage } from 'ngx-webstorage';

import { Observable } from 'rxjs';

@Injectable()
export class PlaceService {

  private appConfig = appConfig;
  private devPath = '';
  private devMode: boolean = this.appConfig.devMode;

  private getNearbyPlacesUrl = '/api/v1/place/search_places';
  private getNearbyPlacesByCollectUrl = '/api/v1/place/search_places_by_collect';

  constructor(
    private _http: HttpClient,
    private _storage: LocalStorageService,
    public _router: Router
  ) {
    if (this.devMode) { this.devPath = this.appConfig.localServerUrl; }
  }

	/*
	 *
	 * 		Place
	 *
	 */

  // 抓取附近景點
  getNearbyPlaces(data): Observable<any> {
    let uri = this.devPath + this.getNearbyPlacesUrl;

    return this._http.post(uri, data);
  }

  // 抓取附近收藏景點
  getNearbyPlacesByCollect(data): Observable<any> {
    let uri = this.devPath + this.getNearbyPlacesByCollectUrl;

    return this._http.post(uri, data);
  }

}
