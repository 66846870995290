import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { LocalStorage } from 'ngx-webstorage';

@Injectable()
export class AuthGuardService implements CanActivate {

  @LocalStorage() public currentUser;

  constructor(private _router: Router) { }

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let currentUser = this.currentUser;
    if (currentUser && currentUser.token) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page
    this._router.navigate(['/signin'], {
      queryParams: {
        returnUrl: state.url
      }
    });
    return false;
  }

}
